import { Component } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../../utils/Loader";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { downloadReport, toastError, toastSuccess, visitorRegistrationLink } from "../../../utils/constants"
import { Link } from "react-router-dom";
import VisitorTopMenu from "../../../common_components/visitor_module_topmenu";
import VisitorDrawer from "../../../common_components/visitor_module_drawer";
import VisitorHeader2 from "../../../common_components/visitor_module_header2";
import { getDrawerDetails_action } from "../../../actions/exhibitor_module_actions/exhibitor_dashboard_action/exhibitor_dashboard_action"
import { getVisitorDashboard_action, getVisitorRegistrationCredentials_action, getCoordinatorVisitor_action, getVisitorBadge_action } from "../../../actions/visitor_module_action/visitor_dashboard_action/visitor_dashboard_action"


class Visitor_dashboard extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        //States
        this.state = {
            isLoading: false,

            isCoordinator: false,
            CoordinatorID: '',
            visitorList: [],
            selectedVisitorID: '',
            isVisitorSelect: false,
            coordinatorFN: '',
            coordinatorLN: '',
            coordinatorExhVisitorID: ''
        }
        this.getDrawerDetails = this.getDrawerDetails.bind(this)
        this.getVisitorDashboard = this.getVisitorDashboard.bind(this)
        this.getVisitorCredentials = this.getVisitorCredentials.bind(this)
        this.getVisitorBadge = this.getVisitorBadge.bind(this)
        this.copyToClipboard = this.copyToClipboard.bind(this)
        this.onCircularsClick = this.onCircularsClick.bind(this)
        this.getCoordinatorVisitor = this.getCoordinatorVisitor.bind(this)
        this.onVisitorSelect = this.onVisitorSelect.bind(this)
    }

    // On Component Load
    componentDidMount() {

        this.getDrawerDetails()
        this.getVisitorDashboard()
        this.getVisitorCredentials(0).then(res => {

            this.getCoordinatorVisitor()
        })
    }

    // Get Drawer Details
    getDrawerDetails() {
        this.setState({ isLoading: true })
        this.props.getDrawerDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Get getDrawerDetails", data.data)
                    sessionStorage.setItem("sessionVisitorDrawerData", JSON.stringify(data.data))

                    // Formatting Address
                    var address = data.data[0] != null && data.data[0] != "" && data.data[0]['Address'] != null && data.data[0]['Address'] != "" &&
                        data.data[0]['Address']
                    var addArray = address != "" && address != null && address.split(",")
                    sessionStorage.setItem("FormatedAddress", JSON.stringify(addArray))

                    // Formating Mobile Numbers
                    var mobNo = data.data[0] != null && data.data[0] != "" && data.data[0]['MobileNo'] != null && data.data[0]['MobileNo'] != "" &&
                        data.data[0]['MobileNo']
                    var MobArray = mobNo != "" && mobNo != null && mobNo.split(",")
                    sessionStorage.setItem("FormatedMobNo", JSON.stringify(MobArray))
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    // Get Exhibitior Details
    getVisitorDashboard() {
        this.setState({ isLoading: true })
        this.props.getVisitorDashboard_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Get getExhibitorDashboard", data.data)
                    sessionStorage.setItem("sessionHeaderData", JSON.stringify(data.data))
                    this.setState({ exhibitorDashboardList: data.data, isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    // Get Exhibitior Details
    getCoordinatorVisitor() {
        this.setState({ isLoading: true })
        return new Promise((resolve => {
            this.props.getCoordinatorVisitor_action(this.state.CoordinatorID)
                .then((data) => {
                    if (data.error != 1) {
                        console.log("Get getCoordinatorVisitor_action", data.data[0])
                        // sessionStorage.setItem('VisitorDataSS', JSON.stringify(data.data[0]))
                        var obj = {}
                        obj.AccountID = this.state.CoordinatorID
                        obj.ExhVisitorID = this.state.coordinatorExhVisitorID
                        obj.PersonIncharge = this.state.coordinatorFN
                        obj.LastNamePersonIncharge = this.state.coordinatorLN
                        data.data.unshift(obj)
                        // if (data.data[0].isCoordinator) {
                        data.data.map(item => {
                            if (item.AccountID == this.state.CoordinatorID) {
                                this.setState({ selectedVisitorID: item.AccountID })
                            }
                        })
                        this.setState({ visitorList: data.data })
                        // }
                        this.setState({ isLoading: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                }).catch((err) => {
                    console.log(err)
                })
        }))
    }

    getVisitorCredentials(status) {
        this.setState({ isLoading: true })
        return new Promise((resolve => {
            this.props.getVisitorRegistrationCredentials_action(status == 0 ? true : false, this.state.selectedVisitorID)
                .then((data) => {
                    if (data.error != 1) {
                        console.log("Get getVisitorCredentials", data.data[0])
                        status == 0 && sessionStorage.setItem('VisitorDataSS', JSON.stringify(data.data[0]))
                        status == 1 && sessionStorage.setItem('SelectedVisitorDataSS', JSON.stringify(data.data[0]))
                        if (status == 0 && data.data[0].isCoordinator) {
                            this.setState({ coordinatorFN: JSON.parse(sessionStorage.getItem('loginDataSS')).FirstName, coordinatorLN: JSON.parse(sessionStorage.getItem('loginDataSS')).LastName })
                            this.setState({ isCoordinator: true, CoordinatorID: data.data[0].AccountID, coordinatorExhVisitorID: data.data[0].ExhVisitorID })
                        }
                        setTimeout(() => {
                            resolve(true)
                        }, 200);
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                }).catch((err) => {
                    console.log(err)
                })
        }))
    }

    // Get Visitor Badge
    getVisitorBadge() {
        this.setState({ isLoading: true })
        this.props
            .getVisitorBadge_action(this.state.isVisitorSelect)
            .then((data) => {
                if (data != null) {
                    this.setState({ isLoading: false })
                    console.log('Report data : ', data);
                    var url;
                    url = `${downloadReport}?SessionIdentifier=${data}&Export=true`;
                    const win = window.location.replace(url, '_blank');
                    if (win != null) {
                        win.focus();
                    }
                } else {
                    this.setState({ isLoading: false })
                    toastError('There is some problem to download report. Please try again later!', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({ isLoading: false })
            });
    }

    //Copy To ClipBoard Function
    copyToClipboard(text) {
        navigator.clipboard.writeText(text)
            .then(() => {
                console.log("Text Copy To ClipBoard")
            })
            .catch((err) => {
                console.log("Error Copying Text To Clipboard", err)
            })
    }

    // On Copy Click
    onCopyClick() {
        const copyText = visitorRegistrationLink
        this.copyToClipboard(copyText)
    }

    // On Circular Click
    onCircularsClick() {
        this.props.navigate('/exhCircularList', { state: { userFlag: 'Visitor', isVisitorSelect: this.state.isVisitorSelect } })
    }

    onVisitorSelect(e) {
        const { isLoading, isCoordinator, visitorList, isVisitorSelect, selectedVisitorID } = this.state
        console.log(e.target.value)
        if (e.target.value != '') {
            this.setState({ selectedVisitorID: e.target.value, isVisitorSelect: true }, () => {
                this.getVisitorCredentials(1).then(() => {
                    this.setState({ isLoading: false })
                })
            })
        }
    }



    render() {
        const { isLoading, isCoordinator, visitorList, selectedVisitorID } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <VisitorHeader2 />
                <VisitorDrawer />
                {/*app-content open*/}
                <div className="page">
                    <div className="page-main">
                        <div className="main-content with-dr-container">
                            <div className="">
                                <div className=" width-dr-nav">
                                    <div className="row">
                                        <VisitorTopMenu />
                                    </div>
                                </div>
                                {isCoordinator && <div className='col-md-4 mt-2'>
                                    {/* <div className="country-box"> */}
                                    <div className=" mb-0">
                                        <label className=''>Visitor List</label>
                                        <select className="form-control select2 select-hidden-accessible pl-0"
                                            style={{ width: "100%" }}
                                            value={selectedVisitorID}
                                            onChange={e => this.onVisitorSelect(e)}
                                        >
                                            {/* <option value={''}>Select </option> */}
                                            {
                                                visitorList.length > 0 && visitorList.map((item) => (
                                                    <option key={item.AccountID} value={item.AccountID}>{item.PersonIncharge} {item.LastNamePersonIncharge}</option>
                                                ))
                                            }
                                        </select>

                                    </div>
                                    {/* </div> */}
                                </div>}

                                <div className="p-2 pt-1">
                                    <div className="row" >
                                        <div className="vister-link-part d-flex mt-2">
                                            {/* <div className="col-md-5">
                                                <div class="input-group">
                                                    <label>Link for visitor registration to share with your friends and colleague</label>
                                                    <input type="text" id="copyTarget" class="form-control" value={visitorRegistrationLink} />
                                                    <span id="copyButton" class="input-group-addon btn" title="Click to copy"
                                                        onClick={(e) => this.onCopyClick()}
                                                    >
                                                        <i class="fa fa-clipboard mt-1" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                            </div> */}
                                            {/* <div className="col-md-2">
                                                <label></label>
                                                <span id="copyButton" class="input-group-addon btn single-col1" title="View Visitor Batch"
                                                    onClick={(e) => this.getVisitorBadge(1)}>View Visitor Badge <i class="fa fa-eye mt-1" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                            <div className="col-md-2 p-0">
                                                <label></label>
                                                <span id="copyButton" class="input-group-addon btn single-col1" title="Download Visitor Batch"
                                                    onClick={(e) => this.getVisitorBadge(2)}> Download Visitor Badge <i class="fa fa-download mt-1" aria-hidden="true"></i>
                                                </span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3 mb-4 p-2">
                                    <div className="col-md-3">
                                        <div
                                            className="com-box com-box-10"
                                            style={{ borderBottom: "2px solid #ff9770" }}
                                        >
                                            <div
                                                className="box-count"
                                                style={{
                                                    height: '108px',
                                                    paddingTop: '30px',
                                                    textAlign: "center",
                                                    fontSize: '19px',
                                                    fontWeight: '500',
                                                    fontFamily: '"Poppins", sans-serif'
                                                }}
                                            >
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary mt-2"
                                                    style={{ backgroundColor: "#6c5ffc", color: "#fff" }}
                                                    onClick={(e) => this.getVisitorBadge()}
                                                >
                                                    Download Visitor Badge
                                                </button>
                                            </div>
                                            {/* <hr className="m-0 mt-1" /> */}
                                            <p className="p-2 pt-4 m-0"> </p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div
                                            className="com-box com-box-10"
                                            style={{ borderBottom: "2px solid #4e598c" }}
                                            onClick={(e) => this.onCircularsClick(e)}
                                        >
                                            <div className="box-count p-3">
                                                <span style={{ background: "#4e598c" }}>
                                                    <i
                                                        className="fa fa-id-card-o"
                                                        aria-hidden="true"

                                                    />
                                                </span>
                                            </div>
                                            <div className="box-count-right ">
                                                {/* {exhibitorDashboardList.length > 0 && exhibitorDashboardList[0]['Messages']} */}
                                                {0}
                                            </div>
                                            <hr className="m-0 mt-1" />
                                            <p className="p-2 m-0">Messages / Circulars</p>
                                        </div>
                                    </div>
                                    {console.log(this.state.visitorList, JSON.parse(sessionStorage.getItem('loginDataSS')), JSON.parse(sessionStorage.getItem('VisitorDataSS')))}
                                    {/* <div className="col-md-3">
                                        <div
                                            className="com-box"
                                            style={{ borderBottom: "2px solid #f52f57" }}
                                        >
                                            <div className="box-count p-3">
                                                <span style={{ background: "#f52f57", padding: "2px 8px 4px" }}>
                                                    <i className="fa fa-globe" aria-hidden="true" />
                                                </span>
                                            </div>
                                            <div className="box-count-right ">17</div>
                                            <hr className="m-0 mt-1" />
                                            <p className="p-2 m-0">IEML Water Quality</p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}
export default withRouter(connect(null, { getDrawerDetails_action, getVisitorDashboard_action, getCoordinatorVisitor_action, getVisitorRegistrationCredentials_action, getVisitorBadge_action })(Visitor_dashboard))

